<template>
  <component
    :is="isHealth ? HealthLayout : DefaultLayout"
    :class="`error-page error-page--${error.statusCode}`"
  >
    <div class="error-page__container">
      <ErrorInfo
        :error="props.error"
        :is-production-stand="isProductionStand"
      />

      <template v-if="error.statusCode === 404">
        <div class="error-page__content">
          <div class="error-page__image">
            <img
              :src="imageUrl"
              alt="404 Error"
            />
          </div>
          <h1 class="error-page__title">Ошибка 404</h1>
          <h2 class="error-page__subtitle">Что происходит?</h2>
          <div class="error-page__description">
            По этой ссылке у нас ничего нет.
            <span v-if="!linksError && links?.length">
              &nbsp;Зато есть много других полезных статей и лайфхаков:
            </span>
          </div>
          <ul
            v-if="!linksError && links?.length"
            class="error-page__links"
          >
            <li
              v-for="{ url, title, id, teaserUrl } in links"
              :key="id"
            >
              <a
                :href="getSlugFromUrl(url)"
                :target="teaserUrl ? '_blank' : ''"
                v-html="title"
              ></a>
            </li>
          </ul>
        </div>
      </template>

      <template v-else>
        <div class="error-page__content">
          <div class="error-page__image error-page__image--503">
            <img
              src="~assets/images/error-503.svg"
              alt="503 Error"
            />
          </div>
          <h1 class="error-page__title">Ошибка 503</h1>
          <h2 class="error-page__subtitle">Что происходит?</h2>
          <div class="error-page__description error-page__description--503">
            Что-то не так с нашим сервером. Обновите страницу или немного подождите — скоро всё
            заработает.
          </div>
        </div>
      </template>
    </div>
  </component>
</template>

<script setup lang="ts">
  import getSlugFromUrl from '~/utils/getSlugFromUrl';
  import { isProductionStand } from '~/constants/config';
  import ErrorInfo from '@devhacker/shared/components/ErrorInfo.vue';

  // из-за того что страница с ошибкой является общей для всего сайта, все ее ассеты
  // грузятся на каждой странице, в том числе и AMP, чтобы этого избежать грузим их асинхронно
  const DefaultLayout = defineAsyncComponent(() => import('./layouts/default.vue'));
  const HealthLayout = defineAsyncComponent(() => import('./layouts/health.vue'));

  const props = defineProps({
    error: {
      required: true,
      type: Object,
    },

    isHealth: {
      required: true,
      type: Boolean,
    },
  });

  const imageUrl = ref('');

  watch(
    () => props.isHealth,
    async (isHealth) => {
      const promise = isHealth
        ? import('~/assets/images/error-404-health.svg')
        : import('~/assets/images/error-404.svg');
      imageUrl.value = (await promise).default;
    },
    { immediate: true },
  );

  const fetchLinks = async () => {
    try {
      const fetchFn = props.isHealth
        ? useNuxtApp().$healthApi.fetchHome
        : useNuxtApp().$ironMaidenApi.fetchLastNArticles;
      const response = await fetchFn(3);

      if (response.status !== 200) {
        throw new Error();
      }

      const articles: IArticle[] = response.data.items;
      return articles.map(({ title, url, id, teaserUrl }: IArticle) => ({
        title,
        url,
        id,
        teaserUrl,
      }));
    } catch {
      const errorMessage = 'Error while fetching links inside error page';
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  const { data: links, error: linksError } = await useAsyncData('links', () => fetchLinks());
</script>

<style lang="scss">
  .health-template {
    // TODO: подумать, как можно избавиться от импортантом
    .error-template-two-column {
      .other-content {
        background: #f3f5fc;
      }

      // временно повышаем специфичность, чтобы убрать important
      .left-sidebar-and-other-content.left-sidebar-and-other-content.left-sidebar-and-other-content {
        @include tablet {
          margin-top: 0;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .error-page {
    width: 100%;
    border: 1px solid #e7e7e7;
    margin-bottom: 16px;
    min-width: unset;
    max-width: unset;

    @include tablet {
      border: none;
    }

    &__content {
      margin: 0 auto;
      @include tablet {
        max-width: 592px;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      padding: 32px 24px;
      background: white;
      height: 100%;

      @include tablet {
        margin: 0 auto;
        max-width: 592px;
      }

      @include big-tablet {
        height: calc(100% - 24px);
        margin-bottom: 24px;
        min-width: 640px;
        max-width: 640px;
        border: 1px solid #e7e7e7;
      }

      @include desktop {
        min-width: 780px;
        max-width: 780px;
      }
    }

    &__image {
      text-align: center;
      margin-bottom: 16px;

      & > img {
        width: 288px;
        height: 232px;
      }

      &--503 > img {
        width: 264px;
        height: 142px;
      }
    }
    &__title {
      @include fontH1;

      text-align: center;
      margin-bottom: 32px;

      @include tablet {
        margin-bottom: 40px;
      }
    }
    &__subtitle {
      @include fontH2;

      margin-bottom: 12px;
    }
    &__description {
      @include fontNormalText;

      margin-bottom: 8px;

      &--503 {
        margin-bottom: 0;
      }
    }
    &__links {
      @include fontNormalText;

      padding-left: 16px;

      li {
        a {
          color: $secondary;
          text-decoration: none;
          position: relative;
          @include hover {
            border-bottom: 2px solid;
          }
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    &__error {
      word-break: break-word;
    }
  }

  .dev-info {
    :deep(pre) {
      padding-left: 2rem;
    }

    code {
      overflow: auto;
      margin: 0;
    }
  }
</style>
